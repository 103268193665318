module.exports={
  "name": "canvasimo",
  "version": "0.8.0",
  "description": "An HTML5 canvas drawing library, with 150+ useful methods, jQuery-like fluent interface, and cross-browser compatibility enhancements.",
  "main": "dist/index.js",
  "module": "build/index.js",
  "types": "build/index.d.ts",
  "directories": {
    "example": "examples"
  },
  "scripts": {
    "prepublishOnly": "npm run dist",
    "dist": "./scripts/dist",
    "start": "./scripts/start",
    "build": "NODE_ENV=production ts-node scripts/generate-docs.tsx",
    "typecheck": "tsc --project tsconfig.json --noEmit",
    "lint": "tslint --project tsconfig.json '{docs/src,src,tests,types,scripts}/**/*.@(ts|tsx)'",
    "tests": "jest",
    "test": "npm run lint && npm run typecheck && npm run tests -- --coverage --runInBand && if grep -rn FIXME src; then exit 1; fi;"
  },
  "repository": {
    "type": "git",
    "url": "git+https://github.com/jakesidsmith/canvasimo.git"
  },
  "keywords": [
    "sensible",
    "simple",
    "fluent",
    "canvas",
    "api",
    "interface",
    "html5",
    "chain",
    "canvasimo"
  ],
  "author": "Jake 'Sid' Smith",
  "license": "MIT",
  "bugs": {
    "url": "https://github.com/jakesidsmith/canvasimo/issues"
  },
  "homepage": "https://github.com/jakesidsmith/canvasimo#readme",
  "dependencies": {},
  "devDependencies": {
    "@types/browserify": "^12.0.33",
    "@types/chokidar": "^1.7.4",
    "@types/glob": "^5.0.35",
    "@types/jest": "^24.0.11",
    "@types/mkdirp": "^0.5.2",
    "@types/node": "^9.4.0",
    "@types/react": "^16.0.36",
    "@types/react-dom": "^16.0.3",
    "@types/rimraf": "^2.0.2",
    "brfs": "^1.4.4",
    "browserify": "^16.2.3",
    "chokidar": "^2.0.0",
    "concurrently": "^3.5.1",
    "core-js": "^2.5.3",
    "derequire": "^2.0.6",
    "exorcist": "^1.0.0",
    "glob": "^7.1.2",
    "http-server": "^0.11.1",
    "jest": "^24.7.1",
    "minifyify": "^7.3.5",
    "mkdirp": "^0.5.1",
    "raf": "^3.4.0",
    "react": "^16.5.2",
    "react-dom": "^16.5.2",
    "rimraf": "^2.5.4",
    "ts-jest": "^24.0.2",
    "ts-node": "^4.1.0",
    "tsify": "^3.0.4",
    "tslint": "^5.9.1",
    "tslint-eslint-rules": "^4.1.1",
    "typescript": "^3.1.6"
  },
  "jest": {
    "testURL": "http://localhost",
    "collectCoverageFrom": [
      "src/**/*.{ts,tsx}"
    ],
    "testRegex": "(/tests/.*|\\.(test|spec))\\.(ts|tsx|js|jsx)$",
    "testPathIgnorePatterns": [
      "tests/helpers/",
      "tests/mocks/"
    ],
    "moduleFileExtensions": [
      "ts",
      "tsx",
      "js",
      "jsx"
    ],
    "transform": {
      "^.+\\.tsx?$": "ts-jest"
    }
  }
}
